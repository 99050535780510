import { E_AD_CATEGORY_NAME } from '@common/enums';
import type { TCreative } from '@common/types';

export const ADS_COLLECTION_FI: TCreative[] = [
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_SOLUTION,
    slotId: 'ounp-wfib-CCwg',
    title: 'Background Image',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/YUVS_Ravintoraisio.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_VIDEO_SOLUTION,
    slotId: '9Jxb-Lfzh-0KRC',
    title: 'Background Image',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/YUVS_MTV.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNIVERSAL_DISPLAY_SOLUTION,
    slotId: 'XYAr-I34j-TyhE',
    title: 'Gallery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/DE/Background/30-Cyberport-Aplle.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: '0Fj7-aPVu-186w',
    title: 'Wipe',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/compressed_Telia.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'Djk3-4HB6-uZVY',
    title: 'Tap',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/McD_fries.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'NTDy-MHbw-DpJj',
    title: '3D Gallery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/ifolor_fathers_day_mystery_ad.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: '3SWx-2ju4-WaYI',
    title: '3D Gallery',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/ifolor_christmas_mystery_ad.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'nO8G-Nyrh-C46p',
    title: 'Poll',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/hbo_barbie_mystery_ad.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'agMl-jaKz-lGq9',
    title: 'Gyro',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/novart_gyro_1.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'mkj3-sqOW-DdsM',
    title: 'Gyro',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/novart_gyro_2.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'tpnu-npjh-HROJ',
    title: 'Tap',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/novart_tap.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'YzGC-NtcP-2FYQ',
    title: 'Swipe',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/Mystery-Ad_Orion-Bevita_swipe.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'ylxA-x8OP-mHpH',
    title: 'Hotspot',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/Mystery-Ad_DNA_Hotspot.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: '2mcs-R6tH-rXyN',
    title: 'Slider',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/MysteryAd_SinebrychoffGarage_Slider.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_AD,
    slotId: 'qEAD-ratL-bkEZ',
    title: 'Tap',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/MysteryAd_FroneryClassic_Tap.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_MYSTERY_SCROLLER,
    slotId: 'EGsu-WREw-xESY',
    title: 'Video Integration',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/ICE_mysteryscroller.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_ZOOM_AD,
    slotId: 'FLET-F0X1-LFXk',
    title: 'Blurred Background',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/Samsung_ZoomAd.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'P3IS-T5TB-Zjyk',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/mtv3_katsomo_branded_takeover.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'Kotf-76Qs-HURZ',
    title: 'Mini Game',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/picadeli_branded_takeover.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'TXLi-HLO4-m5cR',
    title: 'Animation',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/Branded-Takeover_-MTV3_Animation.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_BRANDED_TAKEOVER,
    slotId: 'Gaqs-12D7-u9P5',
    title: 'Static',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/Branded-Takeover_-DNA.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'f6Em-FIPE-yQl2',
    title: 'Wipe',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/compressed_Telia.png',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'mKJ3-Y2f8-U1NA',
    title: 'Standard',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/McD_fries.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'GFIs-qYuQ-RyDg',
    title: 'Video Integration',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/Understitial-Ad_ApolloMatkat_Video-Integration.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_UNDERSTITIAL_AD,
    slotId: 'HnMT-mcJD-utS4',
    title: 'Slider',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/Understitial-Ad_Sinebrychoff-Garage_Slider.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_INLINE_VIDEO_AD,
    slotId: 'kQXF-EYxk-3o0x',
    title: 'Standard',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/WB_InlineVideo.jpg',
  },
  {
    category: E_AD_CATEGORY_NAME.YOC_INLINE_VIDEO_AD,
    slotId: 'DzTd-qFJI-lxUv',
    title: 'Standard',
    displayMode: 'mobile',
    backgroundUrl: 'https://cdn.yoc.com/showroom/FI/Background/Spain_InlineVideo.jpg',
  },
];

import { CSSProperties, FunctionComponent, memo, useEffect, useRef, useState } from 'react';
import { isMobileOnly as isMobile } from 'react-device-detect';
import PreviewScreen from 'src/components/PreviewScreen';
import QRCodeLink from 'src/components/QRCodeLink';
import { Route } from 'type-route';

import { E_PREVIEW_MODE, E_SHOWROOM_THEME } from '@common/enums';
import { addSuffixToPreviewUrl, getAdCategoryBySlotId, getMultiFramePreviewHelper, isDesktopOnly, isMobileOnly } from '@common/helpers';
import { useDmexco } from '@common/hooks';
import { useThemeStore } from '@common/stores';
import { E_AD_PRODUCT_KEY } from '@common/types';
import { useScaleChildToFitParent } from '@components/Gallery/hooks';
import Switcher from '@components/Switcher';
import { TLinkData } from '@components/ViewGallery/types';
import { routes, useRoute } from '@navigation/router';
import { generateAdPreviewUrl } from '@pages/AdPreview/helpers';

import { getPreviewType } from './helpers';
import { TBasicViewProps } from './types';

import './styles.scss';
import stylePreviewScreen from '@components/PreviewScreen/styles.module.scss';
import viewGalleryStyles from '@components/ViewGallery/styles.module.scss';

const BasicView: FunctionComponent<TBasicViewProps> = (props) => {
  const { adData } = props;

  const {
    name: routeName,
    params: { slotId, mode: paramsMode },
  } = useRoute() as Route<typeof routes.preview>;

  // THEME
  const { theme } = useThemeStore();
  const isDMEXCOMode = useDmexco();
  const isWebsite = theme === E_SHOWROOM_THEME.WEBSITE;

  // Preview Mode
  const initMode = getPreviewType(adData);
  const [previewMode, setPreviewMode] = useState<E_PREVIEW_MODE>(() => {
    return paramsMode === E_PREVIEW_MODE.MOBILE || paramsMode === E_PREVIEW_MODE.DESKTOP
      ? paramsMode
      : initMode ?? E_PREVIEW_MODE.MOBILE;
  });

  useEffect(() => {
    // update `previewMode` value every time `adData` changes (not empty) ensuring that `previewMode` is set correctly
    if (initMode) {
      setPreviewMode(initMode);
    }
  }, [initMode, adData]);

  useEffect(() => {
    if (isWebsite && isMobile) {
      document.body.classList.add('preview-mobile');
      document.body.classList.remove('mobile');
    }

    return () => {
      if (!(isWebsite && isMobile)) {
        document.body.classList.remove('preview-mobile');
      }
    };
  }, [isWebsite]);

  // URL Management
  useEffect(() => {
    if (routeName === 'preview' && slotId) {
      // always update `preview` route if `previewMode` was changed
      routes.preview({ slotId, mode: previewMode }).replace();
    }
  }, [previewMode, routeName, slotId]);

  // SCALE
  // TODO: move it into PreviewScreen hook (?)
  const containerRef = useRef<HTMLDivElement>(null);
  const dimensions = {
    mobile: {
      clientWidth: Number(stylePreviewScreen.mobileWidth),
      clientHeight: Number(stylePreviewScreen.mobileHeight),
    },
    desktop: {
      clientWidth: Number(stylePreviewScreen.desktopWidth),
      clientHeight: Number(stylePreviewScreen.desktopHeight),
    },
  };

  const { scale } = useScaleChildToFitParent(containerRef, dimensions[previewMode]);
  const { fullUrl } = generateAdPreviewUrl(previewMode, adData, slotId);
  const modifiedURL = (() => {
    const urlParams = [];
    if (adData?.productKey === E_AD_PRODUCT_KEY.YISV) {
      urlParams.push(`product=${E_AD_PRODUCT_KEY.YISV.toLowerCase()}`);
    }
    return addSuffixToPreviewUrl(fullUrl, urlParams);
  })();

  const isSwitchVisible = adData?.restrictShowcasePreview === 'mobile-and-desktop' && adData?.previewType !== 'desktop';

  const iframeUrlData: TLinkData = isDMEXCOMode
    ? {
      [E_PREVIEW_MODE.MOBILE]: !isDesktopOnly(adData)
        ? generateAdPreviewUrl(E_PREVIEW_MODE.MOBILE, adData, slotId)
        : null,
      [E_PREVIEW_MODE.DESKTOP]: !isMobileOnly(adData)
        ? generateAdPreviewUrl(E_PREVIEW_MODE.DESKTOP, adData, slotId)
        : null,
      name: getAdCategoryBySlotId(slotId) || adData.productKey,
    }
    : { [E_PREVIEW_MODE.MOBILE]: null, [E_PREVIEW_MODE.DESKTOP]: null, name: '' };

  const { isForGallery, renderPreviewScreen } = getMultiFramePreviewHelper(iframeUrlData);

  return (
    <>
      {isDMEXCOMode ? (
        <div className={viewGalleryStyles.holder}>
          <div className="frame-container">
            {iframeUrlData[E_PREVIEW_MODE.MOBILE] &&
              renderPreviewScreen(
                E_PREVIEW_MODE.MOBILE,
                // If it's mobile only make mobile frame bigger, if its mobile and desktop make it smaller.
                iframeUrlData[E_PREVIEW_MODE.DESKTOP] !== null ? '1.1' : '1.84',
                viewGalleryStyles['crop-mobile'],
                ['yoc_preview', ...(isDMEXCOMode ? ['prevent_leaving'] : [])]
              )}
            {iframeUrlData[E_PREVIEW_MODE.DESKTOP] &&
              renderPreviewScreen(
                E_PREVIEW_MODE.DESKTOP,
                '0.48',
                viewGalleryStyles['crop-desktop'],
                ['yoc_preview', ...(isDMEXCOMode ? ['prevent_leaving'] : [])]
              )}
          </div>
          {isForGallery && <span className={viewGalleryStyles.title}>{iframeUrlData.name}</span>}
        </div>
      ) : (
        <>
          <div
            className={`
              container ref-container center ${previewMode}
              ${routeName === 'preview' ? 'preview' : ''}
              `.trim()}
            ref={containerRef}
            style={{ '--preview-scale': scale } as CSSProperties}
          >
            <PreviewScreen
              mode={previewMode}
              url={addSuffixToPreviewUrl(modifiedURL, ['yoc_preview'])}
            />
          </div>
          <Switcher
            previewMode= {previewMode}
            previewUrl= {modifiedURL}
            isSwitchVisible= {isSwitchVisible}
            changePreviewMode={setPreviewMode}
          />
        </>
      )}
      {previewMode === E_PREVIEW_MODE.MOBILE && !(isMobile && isWebsite) && (
        <QRCodeLink
          className="qr-link"
          value={modifiedURL}
          size={isDMEXCOMode ? 120 : 90}
        />
      )}
    </>
  );
};

export default memo(BasicView);
